footer {
  background: transparent linear-gradient(54deg, #F4E8CA 0%, #EECD7F 100%) 0% 0% no-repeat padding-box;
  
  .paddingtarget {
    padding-top: rem-calc(90);
    padding-bottom:rem-calc(40);
  }
  a {
    color: inherit;
    &:hover {
    }
  }

  @include breakpoint(large) {
    .ctrleftright {
      display:flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
  @include breakpoint(medium down) {
    .ctrleftright .ctrright {
      margin-top:2em;
    }
  }
  .ctrlogoaddr {
    display:flex;
    align-items: flex-end;
    gap:rem-calc(40);
  }
  .ctrlogo img {
    display: block;
    @include rem-calc-sl(width, 274);
  }
  .ctraddr {
    margin-bottom:-0.2em;
  }

  .imgrigifutter {
    display: block;
    @include rem-calc-sl(width, 130);
    transform: rotate(-5deg);
  }

  .copyright {
    margin-top:5em;
    text-align: center;
  }

  .bgfooter-poshelper {
    position: relative;
  }
  .bgfooter {
    position: absolute;
    right:0;
    @include rem-calc-sl(top, -120);

    @include rem-calc-sl(width, 464);
    aspect-ratio: 464 / 157;
    background-image: url('/assets/app/images/bgfooter.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

