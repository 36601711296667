@include breakpoint(large) {
    .grid-21 {
        display:grid;
        grid-template-columns: 2fr 1fr;
        gap:var(--gap1);
    }
    .grid-12 {
        display:grid;
        grid-template-columns: 1fr 2fr;
        gap:var(--gap1);
    }
    .grid-11 {
        display:grid;
        grid-template-columns: 1fr 1fr;
        gap:var(--gap1);
    }
}