//$greenbg: #357C53;
//$greentext: #1D8255;
$greenbg:#008450;
$greentext:#008450;

body {
    --gap1:#{rem-calc(32)};
    /*
    --buttontop:#{rem-calc(50)};
    
    --borderwidth:2px;
    @media (min-device-pixel-ratio: 2) {
        --borderwidth:1.5px;
    }
    */
}