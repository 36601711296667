//global font-size (small, medium, large)
// main {
//   font-size: rem-calc(18);
//   @include breakpoint(medium) {
//     font-size: rem-calc(21);
//   }
//   @include breakpoint(large) {
//     font-size: rem-calc(24);
//   }
// }

@mixin typo-base {
  //Dynamic Font Size (mobile, desktop)
  @include font-size-pt(26*0.75, 26);
  font-weight: 400;
  letter-spacing: 0em;
  line-height:math.div(32, 26);
}

body {
  @include typo-base;
  color:$greentext;
}
header {
  color:#000000;
}
footer {
  @include font-size-pt(16*0.75, 16);
  font-weight: 400;
  letter-spacing: 0em;
  line-height: math.div(20, 16);
}

a:focus {
  outline: none;
}
a:hover {
  color:inherit;
}

@mixin typo-h1 {
  @include font-size-pt(60*0.5, 60);
  font-weight: 700;
  letter-spacing: 0em;
  line-height: math.div(95, 88);
}
@mixin typo-h2 {
  @include font-size-pt(50*0.75, 50);
  font-weight: 700;
  letter-spacing: 0em;
  line-height: math.div(70, 60);
}
@mixin typo-h3 {
  @include font-size-pt(32*0.75, 32);
  font-weight: 700;
  letter-spacing: 0em;
  line-height: math.div(42, 32);
}
@mixin typo-h4 {
  font-size: inherit;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: inherit;
}

/*
@mixin typo-subtitle {
  @include font-size-pt(50*0.75, 50);
  font-weight: 200;
  letter-spacing: 0em;
  line-height: math.div(65, 50);
  text-transform: uppercase;
}

@mixin typo-leadtext {
  font-size:inherit;
  font-weight:700;
  line-height: inherit;
  letter-spacing:inherit;
}

@mixin typo-footer {
  @include font-size-pt(17*0.75, 17);
  font-weight: 400;
  line-height: math.div(22, 17);
  letter-spacing: 0em;
}
*/

h1 {
  @include typo-h1;
  margin-bottom:0.5em;
}

h2 {
  @include typo-h2;
  margin-bottom:0.75em;
}

h3 {
  @include typo-h3;
  margin-bottom:0.75em;
}
h4 {
  @include typo-h3;
  margin-bottom:0.5em;
}

p:last-child {
  margin-bottom: 0;
}


table {
  border-collapse: collapse;
  td, th {
    border:none;
    text-align:left;
    vertical-align: top;
    padding:0 3em 0.3em 0;
  }
}

table.prcompact {
  td, th {
    padding-right: 1em;
  }
}

table.border {
  td, th {
    border:1px solid #cce6dc;
    padding:0.25em;
  }
}