header {
  position: relative;
  background: transparent linear-gradient(204deg, #C2D5F0 0%, #DFE7EF 100%) 0% 0% no-repeat padding-box;
  @include rem-calc-sl(padding, 55 0);

  @include breakpoint(large) {
    .ctrlm {
      display:flex;
      align-items: flex-end;
      gap:rem-calc(85);
    }
  }

  .logo {
    @include rem-calc-sl(width, 250);
  }

  #menu-desktop {
    @include font-size-pt(20*0.75, 20);
    margin-bottom:-0.3em;
  }
  #menu-desktop > ul {
    display:flex;
    gap:rem-calc(25);

    a {
      color:#000000;
      &:hover {
        color:$greentext;
      }
    }
    .active > a {
      color:$greentext;
    }
  }
  
  .bgberge {
    @include rem-calc-sl(width, 370);
    aspect-ratio:540 / 190;
    position: absolute;
    right:0;
    bottom:rem-calc(-50);

    z-index: 1;

    background-image:url('/assets/app/images/bgberge.svg');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .standorte {
    position: absolute;
    right:rem-calc(110);
    top:rem-calc(40);

    display:flex;
    gap:rem-calc(40);

    @include font-size-pt(16*0.75, 16);
    font-weight: 400;
    letter-spacing: 0em;
    line-height:math.div(20, 16);
    color:$greentext;
  }

  .ctrhamburger {
    position:relative;
  }
  #hamburger {
    position: absolute;
    right: 0;
    top:rem-calc(20);
    @include breakpoint(small down) {
      top:rem-calc(10);
    }
  }  

  @include breakpoint(medium down) {
    #menu-desktop {
      display:none;
    }
  }
  @media (max-width:1500px) {
    .standorte {
      display: none;
    }
    .bgberge {
      display:none;
    }
  }
  @media (max-width:1023px) {
    .standorte {
      display: flex;
      font-size: 13px;
      top:rem-calc(46);
      margin-right: rem-calc(30);
    }
  }
  @media (max-width:680px) {
    .standorte {
      display: none;
    }
    .standorte.home {
      display: flex;
      position: absolute;
      left: 0;
      right: 0;
      padding: 15px 20px 18px 58px;
      background: $greenbg;
      color: $white;
      top: 148px;
      margin-right: 0;
      a {
        color: $white;
      }
    }
  }
  @media (max-width:639px) {
    .standorte.home {
      top: 98px;
      padding: 12px 15px 15px 32px;

    }
  }
}

header {
  position:sticky;
  top:0;
  z-index: 20;
}

header {
  transition:padding .2s;
  .logo {
    transition:width .2s;
  }
  .bgberge {
    transition:width .2s;
  }
}

header.opaque {
  #hamburger {
    top: rem-calc(10);
    @include breakpoint(small down) {
      top:rem-calc(3);
    }
  }

  @include rem-calc-sl(padding, 25 0);
  .logo {
    @include rem-calc-sl(width, 180);
  }
  .standorte {
    display:none;
  }
  .bgberge {
    @include rem-calc-sl(width, 220);
    bottom:rem-calc(-25);
  }
}