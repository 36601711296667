.bl-linkboxes {
    h2 {
        text-align: center;
    }

    .boxes {
        display:grid;
        grid-template-columns:repeat(4, 1fr);
        gap:var(--gap1);

        @include breakpoint(medium down) {
            grid-template-columns:repeat(2, 1fr);
        }    
        @include breakpoint(small down) {
            grid-template-columns:repeat(1, 1fr);
        }    
    }
    .box {
        background-color: #ffffff;
        box-shadow: 0px 4px 14px #0000002E;
    }
    .ctrtext {
        padding:1em 1.2em 2em 1.2em;
    }
    .morelink {
        margin-top:0.5em;
        color:#000000;
        font-weight: 700;
    }

    .ctrtext {
        @include font-size-pt(20*0.75, 20);
        font-weight: 400;
        letter-spacing: 0em;
        line-height: math.div(26, 20);      
    }

    .box {
        display:flex;
        flex-direction: column;
        .ctrimage {
            overflow: hidden;
            flex:0 0 auto;
            img {
                transition: all 0.2s ease-in-out;
            }
        }
        .ctrtext {
            flex:1 0 auto;
        }
    }

    .ctrtext {
        display:flex;
        flex-direction: column;
        .text {
            flex:1 0 auto;
        }
        .morelink {
            flex:0 0 auto;
            .icon {
                vertical-align: -0.2em;
                margin-left: 0.4em;
            }
        }
    }

    .box:hover {
        .ctrimage {
            img {
                transform: scale(1.05);
            }
        }
    }
}
