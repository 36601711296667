.bl-downloadlist {
    ul {
        @include reset-list;
    }

    color:black;

    li {
        display:flex;
        gap:0.5em;
        align-items: center;
    }

    a {
        color:inherit;
    }
}