ul.menu {
  @include reset-list;
}
.dropdown.menu > li > a {
  //foundation reset
  padding:0;
}
.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
  //foundation reset
  display:none;
}
.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
  //foundation reset
  padding-right: 0;
}

.dropdown .is-dropdown-submenu {
  background-color:rgba(255, 255, 255, 0.85);
  border:none;

  $xshift:rem-calc(20);
  transform:translate($xshift*-1, rem-calc(10));

  padding-top:0.25em;
  padding-bottom:0.25em;

  a {
    display: block;
    padding:0.5em $xshift;

    @include font-size-pt(18*0.75, 18);
    font-weight: 400;
    letter-spacing: 0em;
    line-height:math.div(26, 22);
  }
}

#menu-offcanvas {
  padding-top: rem-calc(40);
  > ul.menu {
    border-top: 1px solid $white;
    > li {
      border-bottom: 1px solid $white;
    }
  }
  a {
    color: $white;
    &:hover {
      opacity: 0.8;
    }
  }
  .active {
    background-color: #3DA28C;
  }

  .submenu-toggle::after {
    border-color: white rgba(0,0,0,0) rgba(0,0,0,0);
  }

}