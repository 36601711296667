section {
    --mpbaseunit: 1.5rem;
    @include breakpoint (small down) {
        --mpbaseunit: 0.75rem;
    }
    &, & > .inner {
        @for $i from -15 through 10 {
            &.pt_#{$i} {
                padding-top:calc(#{$i} * var(--mpbaseunit));
            }
            &.pb_#{$i} {
                padding-bottom:calc(#{$i} * var(--mpbaseunit));
            }

            &.mt_#{$i} {
                margin-top:calc(#{$i} * var(--mpbaseunit));
            }
            &.mb_#{$i} {
                margin-bottom:calc(#{$i} * var(--mpbaseunit));
            }
        }
    }
}

//section bg
section {
    & > .inner {
        position: relative;
    }
    .wconstrain {
        //enable stacking
        position: relative;
    }
    .sectionbg {
        position:absolute;
        right:0;
        bottom:rem-calc(-13);
        width:rem-calc(934);
        aspect-ratio: 934/220;

        background-image: url('/assets/app/images/sectionbg/tiere.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right bottom;
    }
}