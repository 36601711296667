.bl-timeline {
    .item {
        h2 {
            font-size: 1.2em;
        }
        .text {
            font-size: 0.8em;
        }
        .ctrimage {
            margin-top: 1em;
            img {
                width:100%;
                display: block;
            }
        }
        .imglegend {
            font-size: 0.8em;
            font-weight: 700;
        
            margin-top: 1em;
        }        
    }
    @include breakpoint(large) {
        .items {
            display:grid;
            grid-template-columns: 1fr 1fr;
            gap:4em 0;
        }
        .items {
            background: linear-gradient(#000);
            background-repeat: no-repeat;
            background-size: 3px 100%;
            background-position-x: center;

            padding-bottom: 4em;
        }
        .item {
            background: linear-gradient(#000);
            background-repeat: no-repeat;
            background-size: 100% 3px;
            background-position-y: 4em;
        }
        .item:nth-child(odd) {
            .card {
                margin-right:4em;
            }
        }
        .item:nth-child(even) {
            transform: translateY(4em);
            .card {
                margin-left:4em;
            }
        }
    }
    @include breakpoint(medium down) {
        .item + .item {
            margin-top:1em;
        }
    }
    .card {
        background-color: white;
        padding:1em 1.2em;
        box-shadow: 0px 4px 14px #0000002E;
    }
}