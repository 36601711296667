.bl-team {
    margin-bottom: 4em;
    h2 {
        @include font-size-pt(30*0.75, 30);
        //max-width: rem-calc(700);
    }

    .items {
        display:grid;
        grid-template-columns:repeat(4, 1fr);
        gap:var(--gap1);

        @include breakpoint(large down) {
            grid-template-columns:repeat(3, 1fr);
        }    
        @include breakpoint(medium down) {
            grid-template-columns:repeat(2, 1fr);
        }    
        @include breakpoint(small down) {
            grid-template-columns:repeat(1, 1fr);
        }    
    }
    .item {
        min-width:0; //prevent grid item grow
        background-color: #ffffff;
        box-shadow: 0px 4px 14px #0000002E;
    }
    .ctrtext {
        padding:1em 1.2em;
    }

    .ctrtext {
        @include font-size-pt(20*0.75, 20);
        font-weight: 400;
        letter-spacing: 0em;
        line-height: math.div(37, 28);      
        
        color:black;

        h3 {
            font-size:inherit;
            margin-bottom: 0;
        }
    }

    .introtext {
        margin-bottom: 1em;
    }
}

.bl-team .filter {
    user-select: none;

    display:flex;
    flex-wrap: wrap;
    gap:var(--gap1);
    margin-bottom:1em;
    
    @include breakpoint(small down) {
        gap:calc(var(--gap1) / 2);
    }

    a {
        //Filterbutton
        color:#fff;
        padding: .55em 1.5em;
      
        background-color:#008450;
        border-radius: 8px;  
      
        font-size: 0.8em;
        transition: all 0.2s ease-in-out;
      
        &.pressed {
            color:#ffffff;
            background: #000;
        }
    }  
}

.bl-team[data-usefilter="1"] .item {
    display:none; //Kein Standardfilter mehr, zu Beginn alle ausgeblendet.
}