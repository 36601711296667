.bl-aktuelllist {
    @include breakpoint(large) {
        .item {
            display:grid;
            grid-template-columns: 1fr 2fr;
            gap:var(--gap1);
        }
        .item:nth-child(even) {
            grid-template-columns: 2fr 1fr;
            .colimage {
                order:2;
            }
        }
    }
    @include breakpoint(large) {
        .item + .item {
            margin-top: 1em;
        }
    }

    .item + .item {
        margin-top:4em;
    }

    .image {
        display:block;
        width: 100%;
    }

    .card {
        background-color: white;
        box-shadow: 0px 4px 14px #0000002E;
    }

    .card {
        padding:2em;

        h3 {
            color:#999;
            font-size:0.8em;
            font-weight: 400;
        }
    }
}
