.bl-hero {
    position: relative;

    .heroimg {
        display: block;
        width: 100%;
        height:80vh;
        object-fit: cover;
        object-position: center 50%;
    }

    .logooverlay {
        position: absolute;
        @include rem-calc-sl(top, 80);
        @include rem-calc-sl(right, 80);
        @include rem-calc-sl(width, 170);

        img {
            display: block;
            width: 100%;
        }

        &-rigifutter {
            transform: rotate(-10deg);
        }
    }
}
