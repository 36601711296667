.contentcard {
    --paddlr:2em;
    @include breakpoint(small down) {
        --paddlr:1em;
    }
    padding:var(--paddlr) var(--paddlr);

    background-color: #ffffff;
    box-shadow: 0px 4px 20px #00000029;

    /*
    .ctrheader {
        background-color:$greenbg;
        padding:0.5em var(--paddlr);
        color:#ffffff;
    }
    .ctrtext {
        padding:var(--paddlr) var(--paddlr);
    }
    */
}