.bl-tabs {
}

.bl-tabs .tabheader {
    user-select: none;

    display:flex;
    flex-wrap: wrap;
    gap:var(--gap1);
    margin-bottom:1em;
    
    @include breakpoint(small down) {
        gap:calc(var(--gap1) / 2);
    }

    a {
        //Tab header button
        color:#fff;
        padding: .55em 1.5em;
      
        background-color:#008450;
        border-radius: 8px;  
      
        font-size: 0.8em;
        transition: all 0.2s ease-in-out;
      
        &.pressed {
            color:#ffffff;
            background: #000;
        }
    }  
}