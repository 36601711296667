.buttonbar {

}
.button {
  display: block;
  width: fit-content;
  padding: .55em 1.5em;
  color:#000000;
  background: transparent linear-gradient(360deg, #F4E8CA 0%, #EECD7F 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #eed599;
  border-radius: 8px;  

  font-size: 0.8em;
  transition: all 0.2s ease-in-out;
}

.button {
  //transition:background-color 0.2s;
  &:hover, &:active, &:focus {
    color:#ffffff !important;
    background: $greenbg;
    border: 1px solid #1B7E51;
  }
}

