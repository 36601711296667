#menu-offcanvas {
  padding-top:0.5em;
  z-index: 100;
  a {
    display: block;
    padding:0.25em 1em;
  }
  .is-submenu-item a {
    padding-left: 2em;
  }
  a::after {
    border-color: $white transparent transparent;
  }
}
