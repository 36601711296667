.bl-textwithimage {
    .ctrimage {
        aspect-ratio: 760 / 666;
        position:relative;

        img {
            display:block;
            position: absolute;
            left:0;
            top:0;
            width: 100%;
            height:100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    h1 {
        padding-bottom:0.8em;
        border-bottom: var(--borderwidth) solid #000000;
        margin-bottom:-0.3em;

        @include typo-base;
        text-transform: uppercase;
    }
    h2 {
        @include typo-h1;
    }

    .ctrmorelink {
        margin-top:1.2em;

        img {
            display: block;
            width: 3.875em;
        }
    }

    @include breakpoint(large) {
        display:flex;
        align-items: flex-end;

        .ctrimage {
            width:65%;
        }

        .ctrinfo {
            width:35%;
        }

        $infogap: 35%;
        $infoborder: 2%; //Randabstand

        &.imgpos-left {
            .ctrinfo {
                padding-right:$infoborder;
                h1, h2, .text, .ctrmorelink {
                    padding-left:$infogap;
                }
                h1 {
                    width:fit-content;
                }
            }
        }
        
        &.imgpos-right {
            justify-content: flex-end;
            .ctrimage {
                order: 1;
            }

            .ctrinfo {
                padding-left:$infoborder;
                h1, h2, .text, .ctrmorelink {
                    padding-right:$infogap;
                }
            }
        }
    } 
    @include breakpoint(medium down) {
        h1 {
            margin-top:2em;
            width: fit-content;
        }
        .ctrmorelink {
            margin-top:2em;
        }
    }
}

